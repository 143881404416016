import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { CSSProperties } from "react";

export const mapStyle: CSSProperties = {
  //   position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 410,
      // marginBottom: 10,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    actions: {
      alignItems: "center",
    },
    avatar: {
      backgroundColor: "transparent",
    },
    fablist: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    formControl: {
      width: "100%",
    },
    textfield: {
      width: 100,
    },
    address: {
      overflow: "visible",
    },
    count: {
      width: 100,
    },
    store: {
      width: "100%",
    },
    cluster: {
      width: "100%",
    },
    source: {
      width: "100%",
    },
    description: {
      width: "100%",
    },
  })
);
