import axios, {AxiosError} from 'axios';
import { LocalStorageKeys } from '../types/LocalStorageTypes';
// import {toAuth} from '../App';

const ProtectedAxiosInstance = axios.create();
ProtectedAxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err = error as AxiosError;
    if (err && err.response) {
      if (err.response.status === 401) {
        console.error('Got 401 from server');
        // toAuth();
      }
    }
    return Promise.reject(error);
  },
);

ProtectedAxiosInstance.interceptors.request.use(
  async function (request) {
    let accessToken;
    try {
      accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } catch (error) {
      console.error();
    }

    return request;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const JsonTypeHeaders = {
  'Content-type': 'application/json',
};

export default ProtectedAxiosInstance;
