import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import ReactDOMServer from 'react-dom/server';
import { useStyles, mapStyle } from "./styles";
import GoogleMapsSearch from "../GoogleMapsSearch";
import TrolleyIcon from "../../assets/images/map/marker/trolley-marker-adhoc.svg";
import AwayTrolleyIcon from "../../assets/images/map/marker/trolley-marker-away.svg";
import TrolleyPlusIcon from "../../assets/images/map/marker/trolley-marker-adhoc-plus.svg";
import List from "@material-ui/core/List";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Fab,
  CardActions,
} from "@material-ui/core";
import {
  bounds,
  center,
  libraries,
  REACT_APP_GOOGLE_API_KEY,
} from "../../constants";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { MapMarker } from "../../types/MarkerTypes";
import { Places } from "../../types/PlaceTypes";
import ConfirmationDialog from "../ConfirmationDialog";
import { TrolleyData } from "../../types/TrolleyDataTypes";
import { ExpandMore, Save } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { geocodeLatLng } from "../../helpers/GoogleMapsHelpers";
import { random } from "lodash";
import { createTrolleyCollection, getPendingTrolleysByCluster, updateTrolleyCollectionStatus } from "../../api/Trolley";
import { Trolley, CollectionStatus, TrolleyStatus } from "../../types/TrolleyTypes";
import events, { WaypointEventType } from "../../types/EventTypes";

export default function GoogleMaps() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [trolleys, setTrolleys] = useState<Trolley[]>([]);
  const [constantTrolleys, setConstantTrolleys] = useState<Trolley[]>([]);
  const [updateTrolleys, setUpdateTrolleys] = useState<boolean>(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([]);
  const [locationSelected, setLocationSelected] =
    useState<Places | undefined>(undefined);

  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
  const [pendingDeleteTrolley, setPendingDeleteTrolley] = useState<Trolley | undefined>(undefined);
  const [pendingDeleteMarker, setPendingDeleteMarker] = useState<google.maps.Marker | undefined>(undefined);

  const [currentMarker, setCurrentMarker] = useState<MapMarker>();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: libraries,
    googleMapsApiKey: REACT_APP_GOOGLE_API_KEY ? REACT_APP_GOOGLE_API_KEY : "",
  });

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.setCenter(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  useEffect(() => {
    // map?.panToBounds(bounds);
    map?.panTo(center);
    // map?.setZoom(5);
    // map?.fitBounds(bounds);
  }, [map]);

  useEffect(() => {
    (async () => {
      try {
        const sTrolleys = await getPendingTrolleysByCluster('South Land');
        const eTrolleys = await getPendingTrolleysByCluster('East Land');
        setConstantTrolleys([...sTrolleys, ...eTrolleys]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (constantTrolleys && map) {
      constantTrolleys.map((trolley) => {
        const constantMarker: MapMarker = {
          marker: new google.maps.Marker({
            position: trolley.location,
            draggable: false,
            icon: {
              url: trolley.source === "Smart Tag" ? AwayTrolleyIcon : TrolleyIcon,
              size: new google.maps.Size(45, 45),
              scaledSize: new google.maps.Size(45, 45),
            },
            map: map,
            crossOnDrag: false,
            clickable: true,
          }),
          // place_id: "unknown_marker",
        };
        const constantInfo = new google.maps.InfoWindow({
          content: ReactDOMServer.renderToStaticMarkup(
            <>
              <div style={{fontSize: 14, width: 200 }}><b>Store: </b>{trolley.store.name + " " + trolley.store.sn}</div>
              <div style={{fontSize: 14}}><b>Cluster: </b>{trolley.store.cluster.name === "South Land" ? "Southland" : trolley.store.cluster.name === "East Land" ? "Eastland" : ""}</div>
              <div style={{fontSize: 14}}><b>Trolley count: </b>{trolley.count}</div>
              <div style={{fontSize: 14}}><b>Reported time: </b>{new Date(trolley.reportedTime!).toLocaleString()}</div>
              <div style={{fontSize: 14}}><b>Due time: </b>{new Date(trolley.dueTime!).toLocaleString()}</div>
              <div style={{fontSize: 14}}><b>Source: </b>{trolley.source}</div>
            </>,
          ),
        })
        constantMarker.marker.addListener("click", () => {
          constantInfo.open(
            {
              anchor: constantMarker.marker,
              map,
              shouldFocus: true,
            }
          )
          // map.setZoom(20);
          map.setCenter(constantMarker.marker.getPosition() as google.maps.LatLng);
        });
        constantMarker.marker.addListener("rightclick", async () => {
          setPendingDeleteTrolley(trolley);
          setPendingDeleteMarker(constantMarker.marker);
          setDeleteWarning(true);
          // await deleteTrolleyCollection(trolley.collectionId!);
          // constantMarker.marker.setMap(null);
        })
      });
    }
  }, [constantTrolleys, map]);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(undefined);
  }, []);

  const onRightClick = (e: google.maps.MapMouseEvent) => {
    console.log("clicked " + e.latLng);
    const geocoder = new google.maps.Geocoder();
    if (e.latLng != null) {
      geocodeLatLng(geocoder, e.latLng);
    }
  };

  function onMarkerDrag(mapMarker: MapMarker) {
    console.log(mapMarker.marker.getPosition()?.toString());
    const latlng = mapMarker.marker.getPosition();
    const geocoder = new google.maps.Geocoder();
    let updatedLocation: Places | undefined;
    geocoder.geocode(
      { location: latlng },
      (
        results: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus
      ) => {
        if (status === "OK" && results != null) {
          if (results[0]) {
            updatedLocation = {
              address_components: results[0].address_components,
              formatted_address: results[0].formatted_address,
              geometry: {
                location: results[0].geometry.location,
                viewport: results[0].geometry.viewport,
              },
              place_id: results[0].place_id,
            };
            console.log(updatedLocation);
            mapMarker.place_id = results[0].place_id;
            setLocationSelected(updatedLocation);
            events.emit(WaypointEventType.update, {lat: latlng?.lat, lng: latlng?.lng});
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  // const onMarkerClicked = (place_id: string) => {
  //   const mapMarker = mapMarkers.find((marker) => marker.place_id === place_id);
  //   if (mapMarker?.place_id === locationSelected?.place_id) {
  //     setLocationSelected(undefined);
  //   }
  //   if (mapMarker !== undefined) {
  //     mapMarker.marker.setMap(null);
  //   }
  // };

  const onSelect = (place: google.maps.places.PlaceResult | Places) => {
    if (place === undefined || place.geometry === undefined) {
      enqueueSnackbar("Location cannot be found.", { variant: "error" });
      onCancel();
      return;
    }
    onCancel();
    const location = {
      lat: place.geometry?.location?.lat()
        ? place.geometry?.location?.lat()
        : -37.8136,
      lng: place.geometry?.location?.lng()
        ? place.geometry?.location?.lng()
        : 144.9631,
    };
    // console.log('before panto: ', location);
    map?.panTo(location);
    map?.setZoom(22);
    const mapMarker: MapMarker = {
      marker: new google.maps.Marker({
        position: location,
        draggable: true,
        icon: {
          url: TrolleyPlusIcon,
          size: new google.maps.Size(55, 55),
          scaledSize: new google.maps.Size(55, 55),
        },
        map: map,
        crossOnDrag: false,
        clickable: true,
      }),
      place_id: place.place_id ? place.place_id : "unknown_marker",
    };
    mapMarker.marker.addListener("dragend", () => onMarkerDrag(mapMarker));
    mapMarker.marker.addListener("rightclick", () => {
      // onMarkerClicked(mapMarker.place_id);
      mapMarker.marker.setMap(null);
      setLocationSelected(undefined);
    });
    setCurrentMarker(mapMarker);
    // mapMarker.marker.addListener("rightclick", () => {
    //   onMarkerClicked(mapMarker);
    // });
    const newMapMarkers = [...mapMarkers];
    const markerIndex = newMapMarkers.findIndex(
      (marker) => marker.place_id === mapMarker.place_id
    );
    if (markerIndex === -1) {
      newMapMarkers.push(mapMarker);
    } else {
      newMapMarkers.splice(markerIndex, 1, mapMarker);
    }
    setMapMarkers(newMapMarkers);
    const newTrolley: Places = {
      geometry: {
        location: place.geometry?.location,
        viewport: place.geometry?.viewport,
      },
      address_components: place.address_components,
      formatted_address: place.formatted_address,
      place_id: place.place_id,
    };
    setLocationSelected(newTrolley);
    // const newTrolleys: Places[] = [...trolleys];
    // newTrolleys.push(newTrolley);
    // setTrolleys(newTrolleys);
  };

  const renderList = () => {
    return trolleys.map((result) => {
      return (
        <Accordion key={result.collectionId!}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div>
              <Typography>{result.address}</Typography>
              <Typography color="textSecondary" variant="caption">
                {result.location.lat + ", " + result.location.lng}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CardContent>
              {result.comment &&
                <Typography>
                Comment:{" "}
                {result.comment}
              </Typography>}
              
              <Typography>Cluster: {" " + result.store.cluster.name}</Typography>
              <Typography>
                Store:{" " + result.store.name + " " + result.store.sn}
              </Typography>
              <Typography>
                Reported Time:{" " + new Date(result.reportedTime!).toLocaleString()}
              </Typography>
              <Typography>
                Due Time: {" " + new Date(result.dueTime!).toLocaleString()}
              </Typography>
              <Typography>Source: {result.source}</Typography>
              <Typography>Trolley Count: {result.count}</Typography>
              {/* <Typography>Certain: {result.certain}</Typography> */}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const onConfirm = async (trolleyData: TrolleyData) => {
    console.log("Got Data: " + trolleyData);
    const updatedTrolleys = [...trolleys];
    const uTrolley = updatedTrolleys.find(
      (trolley) => trolley.collectionId === trolleyData.place_id
    );
    if (uTrolley !== undefined) {
      trolleyData.place_id = trolleyData.place_id + ":" + random();
      // uTrolley.selectedCount += trolleyData.selectedCount;
      // setTrolleys([...updatedTrolleys]);
      // enqueueSnackbar("Increased trolley count of existing location!", {
      //   variant: "success",
      // });
      // onCancel();
      // return;
    }
    const newTrolley = await createTrolleyCollection(trolleyData);
    google.maps.event.clearInstanceListeners(currentMarker!.marker);
    currentMarker?.marker.setDraggable(false);
    currentMarker?.marker.setIcon({
      url: TrolleyIcon,
      size: new google.maps.Size(45, 45),
      scaledSize: new google.maps.Size(45, 45),
    });
    const constantInfo = new google.maps.InfoWindow({
      content: ReactDOMServer.renderToStaticMarkup(
        <>
          <div style={{fontSize: 14, width: 200 }}><b>Store: </b>{trolleyData.store}</div>
          <div style={{fontSize: 14}}><b>Cluster: </b>{trolleyData.cluster}</div>
          <div style={{fontSize: 14}}><b>Trolley count: </b>{trolleyData.selectedCount}</div>
          <div style={{fontSize: 14}}><b>Reported time: </b>{new Date(trolleyData.reportedDate!).toLocaleString()}</div>
          <div style={{fontSize: 14}}><b>Due time: </b>{new Date(trolleyData.dueDate!).toLocaleString()}</div>
          <div style={{fontSize: 14}}><b>Source: </b>{trolleyData.source}</div>
        </>,
      ),
    })
    currentMarker?.marker.addListener("click", () => {
      constantInfo.open(
        {
          anchor: currentMarker.marker,
          map,
          shouldFocus: true,
        }
      )
      // map?.setZoom(20);
      map?.setCenter(currentMarker.marker.getPosition() as google.maps.LatLng);
    });
    currentMarker?.marker.addListener("rightclick", async () => {
      setPendingDeleteTrolley(newTrolley);
      setPendingDeleteMarker(currentMarker.marker);
      setDeleteWarning(true);
      // await deleteTrolleyCollection(trolley.collectionId!);
      // constantMarker.marker.setMap(null);
    })
    setTrolleys([...trolleys, newTrolley]);
    enqueueSnackbar("Lost trolley successfully added!", { variant: "success" });
    onCancel();
  };

  const onCancelSelected = () => {
    setLocationSelected(undefined);
    // events.emit(WaypointEventType.update, {lat: undefined, lng: undefined});
    currentMarker?.marker.setMap(null);
  }

  const onCancel = () => {
    if (
      locationSelected !== undefined &&
      locationSelected.place_id !== undefined
    ) {
      // onMarkerClicked(locationSelected?.place_id);
      setLocationSelected(undefined);
    }
  };

  function handleDialogClose() {
    setDeleteWarning(false);
  }

  async function handleTrolleyDelete() {
    try {
      await updateTrolleyCollectionStatus(pendingDeleteTrolley?.collectionId!, CollectionStatus.deleted);
      setPendingDeleteTrolley(undefined);
      pendingDeleteMarker?.setMap(null);
      setPendingDeleteMarker(undefined);
      enqueueSnackbar("Trolley successfully deleted!", { variant: "success" });
    } catch(e) {
      setPendingDeleteTrolley(undefined);
      setPendingDeleteMarker(undefined);
      enqueueSnackbar("Failed to deleted the trolley, please refresh the page and try again", { variant: "error" });
    }
    handleDialogClose();
  }

  return isLoaded ? (
    <div>
       <Dialog
        open={deleteWarning}
        onClose={handleDialogClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this trolley?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTrolleyDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <GoogleMap
        mapContainerStyle={mapStyle}
        center={center}
        options={{
          minZoom: 3,
          // zoom: 5,
          panControl: true,
          fullscreenControl: false,
          // mapTypeId: google.maps.MapTypeId.,
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          streetViewControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          restriction: {
            latLngBounds: bounds,
            strictBounds: false,
          },
        }}
        onRightClick={onRightClick}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
      </GoogleMap>
      <Box className={classes.card}>
        <div ref={innerRef}>
            <div className={classes.searchBar}>
              <GoogleMapsSearch onSelect={onSelect} />
            </div>
            {locationSelected && (<div style={{margin: 10, position: "absolute", top: 0,
            left: 0,
            right: 0,
            bottom: 0,}}>
             
                <ConfirmationDialog
                  data={locationSelected}
                  onConfirm={onConfirm}
                  onCancel={onCancelSelected}
                />
              
            </div>)}
          
          

          {/* {trolleys.length !== 0 &&<Fab onClick={handleExpandClick} color="primary" variant="extended" style={{margin: 10, position: "absolute", bottom: 0,
            left: 0,
            }}>
                  <ShoppingCartIcon className={classes.extendedIcon} />
                  Trolleys
                </Fab>} */}
          {trolleys.length !== 0 && expanded &&(
            <List dense style={{ padding: 10 }}>
              <Accordion>
                <AccordionSummary>
                  <Typography className={classes.heading}>
                    Trolley Locations
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Typography className={classes.secondaryHeading}>
                    {trolleys.length}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense style={{ scrollBehavior: "smooth", bottom: 0 }}>
                    {renderList()}
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          )}
        </div>
      </Box>
    </div>
  ) : (
    <></>
  );
}
