export enum LocalStorageKeys {
    LOCATION = 'location',
    REFRESH_TOKEN = 'refresh_token',
    ACCESS_TOKEN = 'access_token',
    ROLE = 'role',
  }
  
  export enum LocalChecklistKeys {
    PASS = 'pass',
    VEHICLEID = 'vehicleId',
    SUBMIT = 'submit',
  }
  
  export enum LocalHotspotKeys {
    // change to api later
    HOTSPOT = 'hotspot',
  }
  