import axios from 'axios';
import {REACT_APP_API_ROOT} from '../constants';
import {UserTokens} from '../types/UserTypes';
import { LocalStorageKeys } from '../types/LocalStorageTypes';

export async function login(email: string, password: string) {
  const body = {
    email: email,
    password: password,
  };

  const response = await axios.post<UserTokens>(`${REACT_APP_API_ROOT}/auth/login`, body);

  try {
    localStorage.setItem(
      LocalStorageKeys.ACCESS_TOKEN,
      response.data.data.accessToken,
    );
    localStorage.setItem(
      LocalStorageKeys.REFRESH_TOKEN,
      response.data.data.refreshToken,
    );
    localStorage.setItem(LocalStorageKeys.ROLE, response.data.data.role);
  } catch (e) {
    console.warn(e);
  }

  return response;
}

export async function logout() {
  let refreshToken;
  try {
    refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
  } catch (e) {
    console.warn(e);
  }
  const body = {
    refreshToken: refreshToken,
  };
  console.log(body);
  const response = await axios.put<UserTokens>(`${REACT_APP_API_ROOT}/auth/logout`, body);

  localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
  localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);

  return response;
}
