import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

export const { REACT_APP_GOOGLE_API_KEY } = process.env;
// export const { REACT_APP_API_URL } = process.env;
export const { REACT_APP_API_ROOT } = process.env;

export const center = {
  lat: -37.8136,
  lng: 144.9631,
  // lat: -27.479055075746867,
  // lng: 134.3777962044442,
};

export const bounds = {
  north: -10.131988,
  south: -44.269439,
  east: 157.934394,
  west: 110.561348,
};

export const libraries: Libraries = [
  "places",
  "drawing",
  "geometry",
  "localContext",
  "visualization",
];
