import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import TrolleyFinderTextIcon from "../../assets/images/logo/trolleyfinder-logo.png";
import { Avatar, Container } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { login } from "../../api/Auth";

interface LoginProps {
  setToken: React.Dispatch<any>;
}

async function loginUser(credentials: { email: string; password: string }) {
  return await login(credentials.email, credentials.password);
}

export default function Login({ setToken }: LoginProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let token
    try {
      token = await loginUser({
        email,
        password,
      });
    } catch(e) {
      enqueueSnackbar("Invalid email or password", {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    setToken(token);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={TrolleyFinderTextIcon} alt={"Main Icon"} width={300} />
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputMode="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!validateForm()}
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}
