import { useState } from "react";
import { useStyles } from "./styles";
import { Divider, IconButton, InputBase, Paper, Menu, MenuItem } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import { Autocomplete } from "@react-google-maps/api";
import { useSnackbar } from "notistack";
import { bounds } from "../../constants";
import { geocodeLatLng } from "../../helpers/GoogleMapsHelpers";
import { Places } from "../../types/PlaceTypes";
import { logout } from "../../api/Auth";
import events, { MappingEventType } from "../../types/EventTypes";

interface GoogleMapsSearchProps {
  onSelect: (place: google.maps.places.PlaceResult) => void;
}

export default function GoogleMapsSearch({ onSelect }: GoogleMapsSearchProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [autocomplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await logout();
    events.emit(MappingEventType.logout);
  }
  

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    console.log("autocomplete: ", autocomplete);

    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== undefined && autocomplete.getPlace() !== undefined) {
      const searchedPlace = autocomplete.getPlace();
      if (searchedPlace.name === "") {
        return;
      }
      let coordinates;
      if (searchedPlace.place_id === undefined) {
        const coords = searchedPlace.name?.trim().split(",");
        if (coords?.length == 2) {
          coordinates = {
            lat: parseFloat(coords[0]),
            lng: parseFloat(coords[1]),
          };
          if (isNaN(coordinates.lat) || isNaN(coordinates.lng)) {
            enqueueSnackbar("Invalid Search Entry", {
              variant: "warning",
            });
            return;
          }
          const geocoder = new google.maps.Geocoder();
          let searchedCoordinates: Places | undefined;
          geocoder.geocode(
            { location: coordinates },
            (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) => {
              if (status === "OK" && results != null) {
                if (results[0]) {
                  searchedCoordinates = {
                    address_components: results[0].address_components,
                    formatted_address: results[0].formatted_address,
                    geometry: {
                      location: results[0].geometry.location,
                      viewport: results[0].geometry.viewport,
                    },
                    place_id: results[0].place_id,
                  };
                  onSelect(searchedCoordinates);
                  return;
                } else {
                  window.alert("No results found");
                }
              } else {
                window.alert("Geocoder failed due to: " + status);
              }
            }
          );
          return;
        }
      }
      onSelect(searchedPlace);
    } else {
      enqueueSnackbar("Autocomplete is not loaded yet!", {
        variant: "warning",
      });
    }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      className={classes.search}
      bounds={bounds}
    >
      <Paper className={classes.root}>
        <IconButton
            className={classes.iconButton}
            aria-label="menu"
            onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <InputBase
          className={classes.input}
          autoFocus={true}
          placeholder="Search for trolleys..."
          fullWidth
          inputProps={{ "aria-label": "search google maps" }}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          disabled
          onClick={onPlaceChanged}
        >
          <Search />
        </IconButton>
        {/* <Divider className={classes.divider} orientation="vertical" /> */}
      </Paper>
    </Autocomplete>
  );
}
