export interface Location {
  lat: number;
  lon: number;
  // change later into the entity if got time
}

export enum TrolleyWheelType {
  Standard = 'standard',
  Travelator = 'travelator',
}

export enum TrolleyStatus {
  beacon = 'AT_BEACON',
  branch = 'AT_BRANCH',
  away = 'AWAY',
  offline = 'OFFLINE',
  repair = 'IN_REPAIR',
  decomissioned = 'DECOMISSIONED',
  pendingCollection = 'PENDING_COLLECTION',
  collected = 'COLLECTED',
  adhoc = 'ADHOC',
  adhocUncertained = 'ADHOC_UNCERTAIN',
  staffReport = 'STAFF_REPORT',
  staffReportUncertain = 'STAFF_REPORT_UNCERTAIN',
}

export interface FaultReport {
  type: string;
  comments: string;
  createAt: BigInt64Array;
  updatedAt: BigInt64Array;
  trolleys: string[];
}

export interface Trolley {
    _id?: string;
    sn: string;
    firstCommissioned?: Date;
    currentLocation?: string;
    serviceHistory: string[];
    movementHistory?: string[];
    capacity?: number;
    wheelType?: TrolleyWheelType;
    specialFeatures?: string[];
    reportedTime?: string;
    dueTime?: string;
    address?: string;
    count?: number;
    user?: {
      email?: string;
    }
    location: {
      lat: number;
      lng: number;
      sat?: number;
      rssi?: number;
      accuracy?: number;
      createdAt?: string;
      updatedAt?: string;
    };
    comment?: string;
    batteryLevel: number;
    status: TrolleyStatus;
    createdAt: Date;
    updatedAt: Date;
    unconfirmed?: boolean;
    events: Event[];
    collectionRecords?: CollectionRecord[];
    placeId?: string;
    store: {
      name: string;
      sn: string;
      cluster: {
        name: string;
        company: {
          name: string;
        };
      };
    };
    collectionId?: string
    source?: string,
  };

export interface MarkerObj {
  address?: any;
  secondaryAddress?: any;
  id?: string;
  placeId?: string;
  type?: string;
  store?: Store;
  trolley?: Trolley;
  beacon?: Beacon;
  hotspot?: Hotspot;
}

export interface Cluster {
  id: string;
  name: string;
  description: string;
  branches: Store[];
  createAt: string;
  updatedAt: string;
}

export interface Store {
  _id?: string;
  sn: string;
  name: string;
  address?: string;
  phone?: string;
  placeId?: string;
  createdAt?: string;
  updatedAt?: string;
  company?: {
    _id?: string;
    __v: number;
    name: string;
  };
  cluster?: Cluster;
  boundary?: [];
  location: {
    lat: number;
    lng: number;
    _id?: string;
    timestamp?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  __v: number;
}

export enum EventType {
  report = 'report',
  location = 'location',
  general = 'general',
  final = 'final',
}

export interface Event {
  eventType: EventType;
  timeStamp: string;
  deviceId: string;
  relay: string;
}

export interface Beacon {
  name: string;
  deviceId: string;
  type: string;
  location: {
    lat: number;
    lon: number;
  };
  numberOfTrolley: number;
}

export interface Hotspot {
  _id: string;
  sn: string;
  branch: string;
  description: string;
  location: Location;
  locationDescription: string;
  collectionRecords?: CollectionRecord[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  id: string;
  placeId?: string;
}

export interface Location {
  _id: string;
  lat: number;
  lng: number;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export enum RelayType {
  baseStation = 'BASE_STATION',
  beacon = 'BEACON',
  repeater = 'REPEATER',
}

export enum RelayLocationType {
  collection = 'COLLECTION',
  dropOff = 'DROP_OFF',
  general = 'GENERAL',
}

export interface CustomLocation {
  address?: any;
  secondaryAddress?: any;
  collectionRecord: CollectionRecord;
}

export enum CollectionReportMethod {
  TrolleyFinder = 'TrolleyFinder',
  hotspot = 'hotspot',
  dropoff = 'store drop-off',
  callin = 'phone-call-in',
  observed = 'driver-observed',
  userReport = 'user reported',
}

export enum Source {
  email = "Email",
  phone = "Phone",
  web = "1800 Trolley Web Page",
  app = "Coles Mobile App",
};

export interface CollectionRecord {
  id: string;
  location: {
    lat: number;
    lon: number;
  };
  reportedMethod: CollectionReportMethod;
  arrivalTime: Date;
  departureTime: Date;
  status: CollectionStatus;
  count: number;
  vehicleID: string;
  driverID: string;
  futureDate: Date;
}

export enum CollectionStatus {
  pending = 'PENDING',
  timeout = 'TIMEOUT',
  actioned = 'ACTIONED',
  autoCleared = 'AUTOCLEARED',
  deleted = 'DELETED',
}

export interface Checklist {
  userid?: string;
  vehicleId?: string;
  submit?: boolean;
  pass?: boolean;
}

export interface StoresResult {
  data: Store[];
}

export interface ClusterResult {
  data: Cluster;
}
  