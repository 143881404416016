import React, { useEffect, useState } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LocalStorageKeys } from "./types/LocalStorageTypes";
import GoogleMaps from "./components/GoogleMaps";
import events, {MappingEventType} from "./types/EventTypes";
require("dotenv").config();

export default function App() {
  // const { token, setToken } = useToken();
  const [token, setToken] = useState(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN));

  function deleteToken() {
    setToken("");
  }

  useEffect(() => {
    events.addListener(MappingEventType.logout, deleteToken);
    return function cleanup(){
      events.removeListener(MappingEventType.logout, deleteToken);
    }
  }, []);

  if (!token && !localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)) {
    return <Login setToken={setToken} />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <GoogleMaps/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
