import { CollectionStatus, Trolley, TrolleyStatus } from '../types/TrolleyTypes';
import { TrolleyData } from '../types/TrolleyDataTypes';
import axios, {JsonTypeHeaders} from './ProtectedAxios';
import { REACT_APP_API_ROOT } from '../constants';
import qs from 'qs';

export const getTrolleys = async (status?: TrolleyStatus): Promise<Trolley[]> => {
  const resp = await axios.get<{ data: Trolley[] }>(`${REACT_APP_API_ROOT}/api/trolleys${status ? '?status=' + status : ''}`);
  return resp.data.data;
};

export const getPendingTrolleysByStore = async (storeSn: string): Promise<Trolley[]> => {
  const resp = await axios.get<{ data: Trolley[] }>(
        `${REACT_APP_API_ROOT}/api/collections${storeSn ? '?storeSn=' + storeSn : ''}`,
  );
  return resp.data.data;
};

export const getPendingTrolleysByCluster = async (clusterName: string): Promise<Trolley[]> => {
    const resp = await axios.get<{ data: Trolley[] }>(
        `${REACT_APP_API_ROOT}/api/collections${clusterName ? '?clusterName=' + clusterName : ''}`,
    );
    return resp.data.data;
};

export const getTrolleysByStoreExcept = async (
  branch: string,
  except: TrolleyStatus[],
  placeId?: boolean,
): Promise<Trolley[]> => {
  const resp = await axios.get<{ data: Trolley[] }>(`${REACT_APP_API_ROOT}/api/trolleys`, {
    headers: JsonTypeHeaders,
    params: {
      except,
      branch,
      placeId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return resp.data.data;
};

export const updateTrolleyCollectionStatus = async (
  collectionId: string,
  status: CollectionStatus,
) => {
  const resp = await axios.put(`${REACT_APP_API_ROOT}/api/collection/${collectionId}`, {
    status: status,
  }, {
    headers: JsonTypeHeaders,
  });

  console.log('the data for status updates:', resp.data);
  return resp.data;
}

export const createTrolleyCollection = async (
    data: TrolleyData
): Promise<Trolley> => {
    const body = {
        source: data.source,
        storeSn: data.store,
        count: data.selectedCount,
        location: {
            lat: data.lat,
            lng: data.lng,
        },
        address: data.address,
        reportedTime: data.reportedDate.toISOString(),
        dueTime: data.dueDate.toISOString(),
        comment: data.description,
    }

    const resp = await axios.post(`${REACT_APP_API_ROOT}/api/collection`, body, {
        headers: JsonTypeHeaders,
    });
    return  resp.data.data;
};

export const deleteTrolleyCollection = async (
    collectionId: string
): Promise<Trolley[]> => {
    const resp = await axios.delete(`${REACT_APP_API_ROOT}/api/collection/${collectionId}`, {
        headers: JsonTypeHeaders,
    });
    return resp.data.data;
};

