import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
// import Avatar from "@material-ui/core/Avatar";
import { useStyles } from "./styles";
// import TrolleyIcon from "../../assets/images/map/marker/trolley-marker-missing.svg";
import { Add, Delete, Edit, Send } from "@material-ui/icons";
import { Places } from "../../types/PlaceTypes";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { grey } from "@material-ui/core/colors";
import { TrolleyData } from "../../types/TrolleyDataTypes";
import { getCluster } from "../../api/Store";
import { Store, Source } from "../../types/TrolleyTypes";
import events, { WaypointEventType } from "../../types/EventTypes";

interface ConfirmationDialogProps {
  data: Places;
  onConfirm: (trolleyData: TrolleyData) => void;
  onCancel: () => void;
}

export default function ConfirmationDialog({
  data,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) {
  const classes = useStyles();
  const [count, setCount] = useState<number>(8);
  const [selectedCount, setSelectedCount] = useState<number>(1);
  const [address, setAddress] = useState<string>(
    data.formatted_address ? data.formatted_address : "No Address Found"
  );
  const [edit, setEdit] = useState<boolean>(false);
  const [lat, setLat] = useState<number>(
    data.geometry.location?.lat() ? data.geometry.location?.lat() : 0
  );
  const [lng, setLng] = useState<number>(
    data.geometry.location?.lng() ? data.geometry.location?.lng() : 0
  );
  const [sStores, setSStores] = useState<Store[]>([]);
  const [eStores, setEStores] = useState<Store[]>([]);

  const [store, setStore] = useState<string>("");
  const [cluster, setCluster] = useState<string>("Southland");
  const [calloutDate, setCalloutDate] = useState<Date | undefined>(undefined);
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
  const [source, setSource] = useState<string>('');
  const [certain, setCertain] = useState<string>("yes");
  const [description, setDescription] = useState<string>("");
  const [place_id, setPlaceId] = useState<string>(
    data.place_id ? data.place_id : "no place_id"
  );

  // useEffect(() => {
  //   setEdit(false);
  //   setAddress(
  //     data.formatted_address ? data.formatted_address : "No Address Found"
  //   );
  //   setPlaceId(data.place_id ? data.place_id : "no place_id");
  //   setLat(data.geometry.location?.lat() ? data.geometry.location?.lat() : 0);
  //   setLng(data.geometry.location?.lng() ? data.geometry.location?.lng() : 0);
  // }, [data]);

  useEffect(() => {
    (async () => {
      try {
        setSStores(await getCluster('South Land'));
        setEStores(await getCluster('East Land'));
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    events.addListener(WaypointEventType.update, handlePositionChange);
    return function cleanup(){
      events.removeListener(WaypointEventType.update, handlePositionChange);
    }
  }, []);

  useEffect(() => {
    console.log('Coordinate update');
    console.log('lat: ', lat);
    console.log('lng: ', lng);
  }, [lat, lng]);

  function handlePositionChange(newLoc: {lat: number, lng: number}) {
    setLat(newLoc.lat);
    setLng(newLoc.lng);
  }

  const handleAddressChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setAddress(event.target.value as string);
  };
  const handleStoreChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // if (cluster === 'South Land') {
    //   sStores.map((store) => {
    //    if (store.sn === event.target.value) {
    //       setStore(store);
    //    }
    //   });
    // } else {
    //   eStores.map((store) => {
    //     if (store.sn === event.target.value) {
    //        setStore(store);
    //     }
    //    });
    // }
    setStore(event.target.value as string);
  };
  const handleClusterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCluster(event.target.value as string);
  };
  const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSource(event.target.value as string);
  };
  const handleCertainChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCertain(event.target.value as string);
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDescription(event.target.value as string);
  };

  const submitTrolley = () => {
    if (store !== "" && address !== "") {
      const twoDaysLater = new Date();
      twoDaysLater.setHours(twoDaysLater.getHours() + 48)
      onConfirm({
        reportedDate: new Date(),//calloutDate ? calloutDate : now,
        dueDate: twoDaysLater,//dueDate ? dueDate : now,
        store, //store.sn,
        cluster,
        lat,
        lng,
        address,
        source,
        description,
        certain,
        selectedCount,
        place_id,
      });
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader/>
      <CardHeader/>
      <CardHeader
        subheader={"Coordinates: " + lat + ", " + lng}
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {edit && <Grid item xs={6}></Grid>}
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="cluster">Cluster</InputLabel>
              <Select
                labelId="cluster"
                className={classes.cluster}
                value={cluster}
                fullWidth
                onChange={handleClusterChange}
              >
                <MenuItem
                  value='Southland'
                >
                  Southland
                </MenuItem>
                <MenuItem
                  value='Eastland'
                >
                  Eastland
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="store">Store</InputLabel>
              <Select
                labelId="store"
                className={classes.store}
                value={store} //{store ? store.sn : ""}
                onChange={handleStoreChange}
                disabled={cluster !== "" ? false : true}
                required
              >
                {cluster === 'Southland' ? sStores
                  .map((store) => {
                    return (
                      <MenuItem value={store.sn} key={store.sn}>
                        {store.name + " " + store.sn}
                      </MenuItem>
                    );
                  }) : eStores
                  .map((store) => {
                    return (
                      <MenuItem value={store.sn} key={store.sn}>
                        {store.name + " " + store.sn}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="source">Source</InputLabel>
              <Select
                labelId="source"
                className={classes.source}
                value={source}
                onChange={handleSourceChange}
              >
                {Object.values(Source).map((value) => {
                  return (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comment (optional)"
              multiline
              defaultValue={description}
              className={classes.description}
              onChange={handleDescriptionChange}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1, paddingLeft: 16 }}>
          <Typography>Trolleys:</Typography>
        </div>
        <Pagination
          count={count}
          boundaryCount={2}
          size="small"
          color="primary"
          variant="outlined"
          hideNextButton
          hidePrevButton
          style={{ maxWidth: 450 }}
          onChange={(event: object, page: number) => setSelectedCount(page)}
        />
      </CardActions>
      <CardActions className={classes.actions}>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={onCancel}
          startIcon={<Delete />}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor:
            (!store || !source || !address) ? grey[500] : "#4c8bf5",
            color: "#fff",
          }}
          onClick={submitTrolley}
          endIcon={<Send />}
          disabled={!store || !source || !address}
        >
          Confirm
        </Button>
      </CardActions>
    </Card>
  );
}
