import { EventEmitter } from 'events';

export default new EventEmitter();

export enum MappingEventType {
  logout = "LOGOUT",
}

export enum TrolleyEventType {
  rxCoords = 'rxCoords',
  markersUpdated = 'markersUpdated',
  panTo = 'panTo',
  reportedTrolley = 'reportedTrolley',
  trolleyTotalNumber = 'trolleyTotalNumber',
}

export enum WaypointEventType {
  delete = 'delete',
  add = 'add',
  nav = 'nav',
  zoom = 'zoom',
  updateCurrentLocation = 'updateCurrentLocation',
  mapswitch = 'mapswitch',
  update = 'update',
}

export enum CollectEventType {
  arrive = 'arrive',
  departure = 'departure',
}

export enum ComponentEventType {
  preview = 'preview',
  addMenuClose = 'addMenuClose',
  updateUrl = 'updateUrl',
  route = 'route',
}

export enum ChecklistEventType {
  changeStatus = 'changeStatus',
  external = 'external',
  internal = 'internal',
}

export enum LogoffEventType {
  timeout = 'TIMEOUT',
  user = 'USER',
}
