import { Places } from "../types/PlaceTypes";

export const loadMapApi = () => {
  const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places&language=no&region=NO&v=quarterly`;
  const scripts = document.getElementsByTagName("script");

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsUrl) === 0) {
      return scripts[i];
    }
  }

  const googleMapScript = document.createElement("script");
  googleMapScript.src = mapsUrl;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};

export function geocodeLatLng(
  geocoder: google.maps.Geocoder,
  latlng: google.maps.LatLng
): Places | { message: string; variatn: string } {
  let newPlace: any;
  geocoder.geocode(
    { location: latlng },
    (
      results: google.maps.GeocoderResult[] | null,
      status: google.maps.GeocoderStatus
    ) => {
      if (status === "OK" && results != null) {
        if (results[0]) {
          newPlace = {
            address_components: results[0].address_components,
            formatted_address: results[0].formatted_address,
            geometry: {
              location: results[0].geometry.location,
              viewport: results[0].geometry.viewport,
            },
            place_id: results[0].place_id,
          };
          // return newPlace;
        } else {
          newPlace = {
            message: "No results found.",
            variant: "info",
          };
        }
      } else {
        newPlace = {
          message: "Geocoder failed due to: " + status,
          variant: "error",
        };
      }
    }
  );
  return newPlace;
}
