import axios, {JsonTypeHeaders} from './ProtectedAxios';
import {REACT_APP_API_ROOT} from '../constants';
import {Store, StoresResult} from '../types/TrolleyTypes';

export async function getCluster(
  cluster: string,
  placeId?: boolean,
): Promise<Store[]> {
  const resp = await axios.get<StoresResult>(
    `${REACT_APP_API_ROOT}/api/stores?cluster=${cluster}${
      placeId ? '&placeId=true' : ''
    }`,
    {
      headers: JsonTypeHeaders,
    },
  );

  return resp.data.data;
}

export async function getStoreByID(id: string): Promise<Store> {
  const resp = await axios.get<Store>(`${REACT_APP_API_ROOT}/api/store/${id}`, {
    headers: JsonTypeHeaders,
  });

  return resp.data;
}

export async function getAllStores(placeId?: boolean): Promise<Store[]> {
  const resp = await axios.get<StoresResult>(
    `${REACT_APP_API_ROOT}/api/stores${placeId ? '?placeId=true' : ''}`,
    {
      headers: JsonTypeHeaders,
    },
  );

  return resp.data.data;
}
